<template>
  <b-container>
    <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
    <div v-if="!loading">

      <div v-if="show.table">
        <b-table id="table" striped hover :items="topics" :fields="fields" :filter="filter" @row-clicked="showTopic"></b-table>
       </div>

      <div v-if="show.topic">
        <div>{{ topic.name }}</div>
        <div class="my-4">
          <b-btn class="mb-4 mr-2" variant="outline-primary" @click="showTable">back to table</b-btn>
        </div>
        <div v-if="permissionRecover">
          <b-button v-b-toggle.collapse-1 variant="outline-danger">recover</b-button>
          <b-collapse id="collapse-1" class="mt-2">
            <b-card>
              <b-btn variant="outline-danger" @click="recover">confirm recover</b-btn>
            </b-card>
          </b-collapse>
        </div>
      </div>

    </div>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import _ from 'lodash'

export default {
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.tag.name
    }
  },
  created: function () {
    document.title = "Deleted Topics"
    if (!ac.can(this.user.acgroups).readAny('rricpanel').granted) {
      this.$router.push({ name: 'Start' })
    }
    this.$stat.log({ page: 'admin deleted topics', action: 'open page' })
    this.permissionRecover = ac.can(this.user.acgroups).deleteAny('topic').granted
    this.load()
  },
  data () {
    return {
      fields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        }
      ],
      filter: '',
      loading: true,
      permissionRecover: false,
      show: {
        add: false,
        edit: false,
        topic: false,
        table: true
      },
      topic: {},
      topics: []
    }
  },
  methods: {
    clearShow: function () {
      _.forIn(this.show, (value, key) => {
        this.show[key] = false
      })
    },
    load: async function () {
      this.$logger.debug('loading tags started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = '/topics/deleted'
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('response', response)
        this.topics = response
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    recover: async function () {
      this.$logger.debug(`saving category start`)
      this.saving = true
      try {
        let apiName = 'cosmos'
        let path = `/topic/undelete/${this.topic.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('saved OK:', response)
        this.clearShow()
        this.load()
        this.show.table = true
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    },
    saveNew: async function () {
      this.$logger.debug(`saving category start`)
      this.saving = true
      try {
        let apiName = 'cosmos'
        let path = `/standard/category`
        let params = {
          body: this.tag
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.clearShow()
        this.load()
        this.show.table = true
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    },
    showTopic: function (topic, index, event) {
      this.topic = { ...topic }
      this.clearShow()
      this.show.topic = true
    },
    showTable: function (tag, index, event) {
      this.clearShow()
      this.tag = {}
      this.show.table = true
      this.load()
    }
  }
}
</script>

<style></style>
